@import '../../asset/css/mixins.scss';

.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 60px;
  font-weight: bold;
  @include backgrouncImage;
}