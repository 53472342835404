@import '../../asset/css/mixins.scss';

.resource-list-page {
  height: 100vh;
  overflow-y: scroll;
  padding: 37px 40px;
  @include backgrouncImage;

  .title {
    margin-bottom: 49px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #333333;
  }

  .no-data {
    @include no-data;
  }
}

.resource-list-item {
  margin-bottom: 51px;
  .item-title {
    width: 670px;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    background: #685EE7;
    font-size: 36px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 10px;
    @include ellipsis;
  }

  audio {
    display: block;
    margin: 0 auto;
  }

  .image {
    width: 100%;
    height: 480px;
    border-radius: 10px;
  }
}
