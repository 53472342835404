.book-content-video-container {
  position: relative;
  margin: 0 auto;
  width: 730px;

  .cover {
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 45px;
    background-color: rgba($color: #000000, $alpha: 0.3);

    .size-tip {
      display: flex;
      align-items: center;
      justify-content: center;


      .play-icon {
        width: 183px;
        height: 184px;
        background-repeat: no-repeat;
        background-size: cover;
      }


    }
  }

  .full {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
    /*这里是关键*/
    width: auto;
    height: auto;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }

  video {

    display: block;
    width: 730px;
    height: auto;
    border-radius: 46px;
  }
}