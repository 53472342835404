.video-container {
  position: relative;
  background: rgba(0, 0, 0, 0.3);

  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba($color: #000000, $alpha: 0.3);

    .size-tip {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      padding: 0 30px 0 30px;
      font-size: 28px;
      border-radius: 40px;
      color: #fff;
      background-color: rgba($color: #000000, $alpha: 0.7);

      .play-icon {
        display: block;
        width: 46px;
        height: 46px;
        //margin-right: 20px;
        margin-left: 5px;
        background: url(./image/play-icon.png) no-repeat center/contain;
      }

      .size {
        margin-left: 9px;
        color: #CEAAFF;
      }
    }
  }

  video {
    display: block;
    width: 100%;
    max-height: 480px;
    border-radius: 10px;
  }
}