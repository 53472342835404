.login-page {
  min-height: 100vh;
  padding: 170px 65px;
  overflow: hidden;
  background: url(./image/bg.jpeg) no-repeat;
  background-position: top left;
  background-size: 100%;
  background-color: #969799;


  .title {
    margin-bottom: 143px;
    color: #333333;
    font-size: 44px;
    line-height: 124px;
    font-weight: bold;
  }

  .mobile-box {
    display: flex;
    margin-bottom: 34px;

    .before {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 108px;
      height: 98px;
      margin-right: 14px;
      font-size: 30px;
      font-weight: 500;
      color: #969799;
      background: #F6F7FA;
      border-radius: 10px;
    }

    .mobile {
      display: block;
      width: 499px;
      height: 99px;
      padding: 0 31px;
      font-size: 30px;
      color: #333333;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      background-color: #FFFFFF;
      outline: none;

      &::placeholder {
        color: #B5B5B5;
      }
    }
  }

  .smscode-box {
    display: flex;
    .smscode {
      width: 388px;
      height: 99px;
      padding: 0 32px;
      font-size: 30px;
      margin-right: 14px;
      outline: none;
      color: #333333;
      background: #F6F7FA;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      &::placeholder {
        color: #B5B5B5;
      }
    }

    .smscode-button {
      width: 218px;
      height: 99px;
      font-weight: 500;
      font-size: 30px;
      color: #5A4CE4;
      border: 1px solid #5A4CE4;
      border-radius: 10px;
      background: #FFFFFF;

      &.disabled {
        color: #A099EC;
      }
    }
  }

  .login-btn {
    width: 620px;
    height: 88px;
    font-size: 32px;
    margin-top: 140px;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
    box-shadow: 0px 2px 24px 0px rgba(100, 107, 111, 0.16);
    border-radius: 10px;
    background-color: #715EE7;
  }
}

