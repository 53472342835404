@import '../../asset/css/mixins.scss';


.bind-page {
  height: 100vh;
  @include backgrouncImage;

  .no-data {
    @include no-data;
  }
}