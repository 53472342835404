@import '../../asset/css/mixins.scss';


.book-page {
  padding: 33px 26px 112px 26px;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  .collection-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 1vw;
    height: 1vh;
    z-index: -1;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 80px;
    width: 698px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    line-height: 160px;
    color: #FFFFFF;

  }

  .title-content {
    max-width: 540px;
    font-size: 46px;
    font-weight: bold;
    text-align: center;
    line-height: 160px;
    @include ellipsis;
  }

  .sub-title-content {
    padding-top: 20px;
    max-width: 540px;
    font-size: 46px;
    font-weight: bold;
    text-align: center;
    line-height: 65px;
    @include ellipsis;
  }

  .sub-title-p {
    font-size: 36px;
    font-weight: bold;
    @include ellipsis;
  }

  .list-box {
    margin-top: 73px;
    height: calc(100vh - 327px);
    overflow-y: scroll;

  }

  .item {
    margin-top: 73px;
    padding: 0 100px;
    width: 698px;
    height: 140px;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    line-height: 134px;
    color: #FFFFFF;
    @include ellipsis;

    &:nth-child(1) {
      margin-top: 0;
    }
  }
}