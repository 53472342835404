@import '../../asset/css/mixins.scss';

.book-content-list-page {
  min-height: 100vh;
  width: 100vw;
  padding: 20px 10px;
  background-repeat: repeat;
  background-size: 100vw;


  //.bg {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100vw;
  //  height: 100vh;
  //  z-index: -1;
  //}

  .scrollView {
    width: 100vw;
    height: 100vh;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 60px;
    width: 660px;
    height: 127px;
    margin-bottom: 49px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    line-height: 120px;
    color: #ffffff;
    background: url('./image/contetn-title-bg.png') no-repeat;
    background-size: cover;
  }

  .title-content {
    max-width: 540px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    line-height: 160px;
    color: #FFFFFF;
    @include ellipsis;
  }

  .no-data {
    @include no-data;
  }
}

.book-content-list-item {
  margin-bottom: 20px;

  .item-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px 100px 0 100px;
    width: 730px;
    height: 136px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    line-height: 40px;
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
  }




  .image {
    margin: 0 auto;
    width: 700px;
    border-radius: 2px;
  }
}