article,aside,blockquote,body,button,code,dd,details,div,dl,dt,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hr,iframe,input,legend,li,menu,nav,ol,p,pre,section,td,textarea,th,ul {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // letter-spacing: 1px;
}

html {
  font-size: 50px
}

html, body {
  min-height: 100%;
}

body,button,input,select,textarea {
  font-size: 12px;
  line-height: 1.5;
  font-family: system, -apple-system, BlinkMacSystemFont, 'PingFang SC',
    'Segoe UI', 'Microsoft YaHei', 'wenquanyi micro hei', 'Hiragino Sans GB',
    'Hiragino Sans GB W3', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

fieldset,img {
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,input,select,textarea {
  outline: 0;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  -webkit-appearance: none;
}

textarea {
  resize: none;
  overflow: auto;
}

a:active,a:hover,a:link,a:visited {
  text-decoration: none;
}

em,i {
  font-style: normal;
}

ol,ul {
  list-style: none
}[v-cloak] {
  display: none;
}

iframe {
  border: none;
}

b {
  font-weight: normal;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-moz-placeholder,::-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}
