@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin ellipsis-m($line:2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: $line;
  word-break: break-word;
}

@mixin backgrouncImage($url:url(../image/bg.jpeg))  {
  background: $url no-repeat;
  background-position: top left;
  background-size: 100%;
  background-color: #969799;
}

@mixin no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}