.audio-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .play-btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 153px;
        height: 154px;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 100;
    }

    .pause-btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 153px;
        height: 154px;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 100;
    }

    .play-btn-box {
        width: 40px;
        height: 154px;
    }

    audio {
        display: block;
        margin: 0 auto;
        width: 540px;
        height: 80px;
        overflow: auto;
        margin-left: 60px;

        &::-webkit-media-controls-panel {
            padding: 10px;
            border: none;
            background: -webkit-linear-gradient(left, #61758e, #7e748d);
        }

        &::-webkit-media-controls-play-button {
            background: none;
        }

        &::-webkit-media-controls-seek-back-button {
            background-color: aqua;
        }
    }
}