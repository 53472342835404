@import '../../asset/css/mixins.scss';

.guide-page {
  height: 100vh;
  padding-top: 192px;
  @include backgrouncImage;

  .title {
    margin-bottom: 56px;
    text-align: center;
    font-size: 44px;
    font-weight: bold;
    color: #333333;
  }

  .sub-title {
    margin-bottom: 157px;
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }

  .book-icon {
    display: block;
    width: 539px;
    max-height: 600px;
    margin: 0 auto;
    object-fit: contain;
  }

  .btn {
    display: block;
    width: 620px;
    height: 88px;
    margin: 0 auto;
    font-size: 32px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #685EE7;
    border-radius: 10px;
    box-shadow: 0px 2px 24px 0px rgba(100, 107, 111, 0.16);
  }
}

.new-guide-page {
  padding: 63px 26px 112px 26px;
  height: 100vh;
  background: url('./image/book-bg.jpg') no-repeat;
  background-size: cover;

  .title {
    width: 698px;
    height: 165px;
    background: url('./image/title-bg.png') no-repeat;
    background-size: cover;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    line-height: 160px;
    color: #FFFFFF;
  }

  .list-box {
    margin-top: 73px;
    height: calc(100vh - 327px);
    overflow-y: scroll;

  }

  .item {
    margin-top: 73px;
    padding: 0 100px;
    width: 698px;
    height: 140px;
    background: url('./image/item-bg.png') no-repeat;
    background-size: cover;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    line-height: 134px;
    color: #FFFFFF;
    @include ellipsis;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}